<template>
  <v-dialog
    width="100%"
    max-width="1600px"
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
  >
    <v-card :loading="loading" v-if="item">
      <v-system-bar window dark
        ><span>Kursrechnung</span><v-spacer />
        <v-btn icon @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-toolbar color="info" flat dark>
        <v-row>
          <v-col> <PersonItem :value="item.employee" /> </v-col>
          <v-col>
            <v-list color="info" v-if="item.amount">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Betrag </v-list-item-title>
                </v-list-item-content>
                <CurrencyValue :value="item.amount" />
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-card-text class="ma-0 px-4 pt-4">
        <v-row>
          <v-col cols="12" md="6" lg="5" xl="4" offset-xl="2">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Nummer </v-list-item-subtitle>
                  <v-list-item-title>{{ item.number }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Datum </v-list-item-subtitle>
                  <v-list-item-title
                    ><DateValue :value="item.date" long />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Anlass </v-list-item-subtitle>
                  <v-list-item-title>{{ item.event }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Beschreibung </v-list-item-subtitle>
                  <v-list-item-title>{{ item.description }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Konto </v-list-item-subtitle>
                  <v-list-item-title v-if="item.account"
                    >{{ item.account.number }} ({{ item.account.description }})
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.account.code === 'teachingMaterial'">
                <v-list-item-content>
                  <v-list-item-subtitle>Stufe(n) </v-list-item-subtitle>
                  <GradesInput readonly v-model="item.grades" />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.account.code === 'fieldTrip'">
                <v-list-item-content>
                  <v-list-item-subtitle>Schuljahr</v-list-item-subtitle>
                  <v-list-item-title>
                    {{
                      item.schoolYear ? item.schoolYear.description : "–"
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Zahlung </v-list-item-subtitle>
                  <v-list-item-title v-if="item.payee"
                    >{{ item.payee.description }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.payee.code != 'original'">
                <v-list-item-content>
                  <v-list-item-subtitle>IBAN </v-list-item-subtitle>
                  <v-list-item-title>{{ item.iban }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Status </v-list-item-subtitle>
                  <v-list-item-title v-if="item.status"
                    >{{ item.status.description }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <InvoiceAmount v-model="item" readonly />
            </v-list>
            <v-btn-toggle class="d-flex justify-space-between">
              <ReportButton
                resource="report/invoice"
                :parameters="{ id: item.id }"
                text
                outlined
                dense
                color="red"
                class="flex-grow-1"
                >Rechnung</ReportButton
              >
              <ReportButton
                resource="report/invoicedetails"
                :parameters="{ id: item.id }"
                text
                outlined
                dense
                color="red"
                class="flex-grow-1"
                >Details</ReportButton
              >
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="6" lg="7" xl="4">
            <v-card v-if="item">
              <v-row no-gutters align="center" class="flex-nowrap">
                <v-col cols="6">
                  <v-list-item class="font-weight-medium"
                    ><v-list-item-icon>
                      <v-badge :content="item.details.length">
                        <v-icon>mdi-account-multiple</v-icon>
                      </v-badge> </v-list-item-icon
                    >{{ item.details.amount }} Personen</v-list-item
                  >
                </v-col>
                <v-col cols="2">Spesen</v-col>
                <v-col cols="4">Betrag</v-col>
              </v-row>
              <v-divider />
              <v-row
                no-gutters
                v-for="(item, index) in item.details"
                :key="index"
                class="flex-nowrap"
                align="center"
              >
                <v-col cols="6" class="pl-2">
                  <PersonItem
                    small
                    :disabled="item.amount == 0"
                    :value="item.person"
                  >
                  </PersonItem>
                </v-col>
                <v-col cols="2"
                  ><v-checkbox
                    v-if="item.person.type.code === 'teacher'"
                    readonly
                    v-model="item.expenses"
                  ></v-checkbox
                ></v-col>
                <v-col cols="4" align-self="center" v-if="item.amount > 0">
                  <v-list-item>
                    <CurrencyValue :value="item.amount"
                  /></v-list-item>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <template v-if="item.status.code == 'submitted'">
          <v-btn
            text
            color="primary"
            :to="{
              name: 'FinanceInvoicesSubmittedEdit',
              params: { id: item.id },
            }"
            >bearbeiten</v-btn
          >
          <v-btn
            text
            color="error"
            @click="
              doAction({
                action: 'reject',
                message:
                  'Soll die Rechnung an den/die Mitarbeiter/in zurückgewiesen werden?',
                color: 'error',
              })
            "
            >Zurückweisen</v-btn
          >
          <v-btn
            text
            color="success"
            @click="
              doAction({
                action: 'submitSap',
                message: 'Soll die Rechnung an SAP übermittelt werden?',
                color: 'success',
              })
            "
            >Übermitteln</v-btn
          >
        </template>
        <template v-if="item.status.code == 'submittedSap'">
          <v-btn
            text
            color="error"
            @click="
              doAction({
                action: 'rejectSap',
                message: 'Soll die Rechnung in SAP zurückgewiesen werden?',
                color: 'error',
              })
            "
            >Zurückweisen</v-btn
          >
          <v-btn
            text
            color="success"
            @click="
              doAction({
                action: 'enter',
                message: 'Soll die Rechnung verbucht werden?',
                color: 'success',
              })
            "
            >verbuchen</v-btn
          >
        </template>
        <v-spacer />
        <v-btn text @click="dialog = false">schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "common/components/CurrencyValue";
import DateValue from "common/components/DateValue.vue";
import GradesInput from "./GradesInput";
import InvoiceAmount from "./Components/InvoiceAmount";
import PersonItem from "common/components/PersonItem";
import ReportButton from "@/components/ReportButton.vue";

import { formatDatespan } from "common/utils/date.js";

export default defineComponent({
  components: {
    DateValue,
    CurrencyValue,
    GradesInput,
    InvoiceAmount,
    PersonItem,
    ReportButton,
  },
  props: ["id"],
  data: () => ({
    dialog: true,
    item: null,
    loading: false,
    saving: false,
  }),

  watch: {
    dialog() {
      if (!this.dialog) {
        const parentRoute = this.$route.matched.slice(-2).shift();
        this.$router.push({
          name: parentRoute.name,
        });
      }
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    async doAction({ action, ...args }) {
      if (await this.$root.confirm(args)) {
        await this.apiPatch({
          resource: "finance/invoice",
          id: this.id,
          key: "action",
          value: action,
        });
        this.$emit("update");
        this.dialog = false;
      }
    },
    formatDatespan,
    async fetchData() {
      this.loading = true;
      this.item = await this.apiGet({
        resource: "finance/invoice",
        id: this.id,
      });
      this.loading = false;
    },
  },

  async created() {
    this.fetchData();
  },
});
</script>
